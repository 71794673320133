<template>
  <section v-if="records">
    <h3> 
      Issues For Intake
    </h3>
    <br>
    <span> 
      <b-row style="margin-left:1rem;">
        <b-col cols="8">
          <span v-if="selectedRecords.length === 0"></span>
          <b-dropdown  
            v-else
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="Operations"
            variant="primary"
            size="sm"
          >
            <b-dropdown-item
              @click.stop="startIssueIntake('n')"
            >
              <nobr>
                <span class="mr-1">Intake to Production</span>
                <feather-icon
                  size="20"
                  icon="CheckCircleIcon"
                />  
              </nobr>
            </b-dropdown-item>
            <b-dropdown-item
              @click.stop="startIssueIntake('y')"
            >
              <nobr>
                <span class="mr-1">Intake to Issue Archive</span>
                <feather-icon
                  size="20"
                  icon="CheckCircleIcon"
                />  
              </nobr>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
        <b-col cols="4" align="right">
          <b-row>
            <b-col cols="6">
              <b-form-select
                v-model="filterVolume"
                :options="filterOptions"
                style="margin-bottom:1rem;"
                size="sm"
              />
            </b-col>
            <b-col cols="6" align="left">
              {{ records.length }} of {{ totalRecords }}
            </b-col>
          </b-row> 
        </b-col> 

      </b-row>
    </span>
    <b-card bg-variant="light">
      <b-table
        :items="records"
        :fields="['selectAllRecords', 'vol', 'iss', 'timestamp', 'status']"
        striped
        responsive="sm"
      >
        <template #head(selectAllRecords)>
          <b-form-checkbox
            v-model="selectAllRecordsChecked"
          />
        </template>
        <template #cell(selectAllRecords)="data">
          <b-form-checkbox
            v-if="data.item.intake_in_progress !== 'y'"
            v-model="selectedRecords"
            :value="data.item"
          />
        </template>
      </b-table>
    </b-card>
  </section>
</template> 
 
<script>

import publicationApi from '@/dl_pubmill/apis/publication'

import {
  BCard, BFormCheckbox, BTable, BDropdown, BDropdownItem, BRow, BCol, BFormSelect
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {

  components: {
    BCard,
    BFormCheckbox,
    BTable,
    BDropdown, 
    BDropdownItem, 
    BRow,
    BCol,
    BFormSelect,
  },

  directives: {
    Ripple,
  },
 
  data () {
    return {
      records: null,
      selectAllRecordsChecked: false,
      selectedRecords: [],
      totalRecords: null,
      filterVolume: null,
      filterOptions: null,
    }
  },
  computed: { 
  },
  watch: {   
    selectAllRecordsChecked () {
      this.selectedRecords = []
      if (this.selectAllRecordsChecked === false) {
        return
      }
      for (let i = 0; i < this.records.length; i++) {
        const record = this.records[i]
        if (record.intake_in_progress !== 'y') {
          this.selectedRecords.push(record)
        }
      }
    },
    filterVolume () {
      this.getIssueIntakeZips()
    },
  },
  mounted () {
    this.getIssueIntakeZips()
  },
  methods: {
    getIssueIntakeZips () {
      this.records = []
      publicationApi.getIssueIntakeZips(this.$route.params.issn, this.filterVolume, this, null)
    },
    backFromGetIssueIntakeZips (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
        this.reportResults = 'Error'
      } else {
        console.log('ssss backFromGetIssueIntakeZips', serverData)

        this.totalRecords = serverData.summary.total

        this.filterOptions = [{ value: null, text: 'All Volumes' }]
        for (let i = 0; i < serverData.summary.vols.length; i++) {
          const record = serverData.summary.vols[i]
          this.filterOptions.push({ value: record.vol, text: 'Volume '.concat(record.vol, ' (', record.count, ')') })
        }
        for (let i = 0; i < serverData.results.length; i++) {
          const record = serverData.results[i]
          if (record.intake_in_progress === 'y') record.status = 'Intake in Progress'
          else  record.status = ''
          //console.log('ddddd', record.intake_in_process)
          this.records.push(record)
        }
      }
    },
    startIssueIntake (intakeToIssueArchive) {
      const zipList = []
      for (let i = 0; i < this.selectedRecords.length; i++) {
        const record = this.selectedRecords[i]
        zipList.push(record.zip)
      }
      publicationApi.startIssueIntake(this.$route.params.issn, zipList, intakeToIssueArchive, this, null)
    },
    
    backFromStartIssueIntake (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
        this.reportResults = 'Error'
        this.getIssueIntakeZips()
      } else {
        this.getIssueIntakeZips()
      }
    },
  },
}
</script>
